<template>
  <button
    class="base-btn"
    :class="[computedClass, size]"
    :disabled="loading || disabled"
    v-bind="$attrs"
  >
    <svg
      v-if="loading"
      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      />
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
    <span class="relative z-10"><slot /></span>
  </button>
</template>

<script setup lang="ts">
import { computed, type ButtonHTMLAttributes } from 'vue';
import { useThemeVariant } from '~/composables';
import type { BtnSizes, BtnVariants } from '~/types';

const { btnClassMap } = useThemeVariant();

interface Props extends /* @vue-ignore */ Partial<ButtonHTMLAttributes> {
  variant?: BtnVariants
  size?: BtnSizes
  loading?: boolean
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  size: 'base',
  loading: false,
  disabled: false,
});

const computedClass = computed(() => btnClassMap(props.variant as BtnVariants));
</script>

<style scoped>
.base-btn {
  @apply tracking-wide inline-flex items-center justify-center rounded-md font-semibold outline-2 outline-offset-2 transition-all relative overflow-hidden border active:translate-y-0.5 hover:delay-0 delay-150;
  &.base {
    @apply py-2.5 px-6 text-base;
  }
  &.xl {
    @apply py-5 px-10 text-base;
  }
}
</style>
